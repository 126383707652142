import React, { useState } from 'react'
import Modal from '../../global/Modal'
import ReactDOM from 'react-dom'
import { injectIntl, defineMessages } from 'react-intl'
import Translation from '../../global/Translation'

defineMessages({
    extra_discount_half_trucks: {
        id: 'extra_discount_half_trucks',
        description: 'extra_discount_half_trucks',
        defaultMessage: 'extra discount half trucks'
    },
    extra_discount_full_trucks: {
        id: 'extra_discount_full_trucks',
        description: 'extra_discount_full_trucks',
        defaultMessage: 'extra discount full trucks'
    }
})

const BannerSilestoneDektonBlackWeek = ({
    layout = 'row',
    backgroundColor,
    borderColor,
    textColor,
    showConditionsButtonCatalog,
    showConditionsButtonCheckout,
    titleFontSize,
    percentFontSize,
    intl,
    ...props
}) => {
    const [showConditionsModal, setShowConditionsModal] = useState(false)

    const handleOpenConditionsModal = event => {
        event.preventDefault()
        setShowConditionsModal(true)
    }

    const handleCloseConditionsModal = event => {
        event.preventDefault()
        setShowConditionsModal(false)
    }

    const checkConditionsBtn = (
        <button onClick={event => handleOpenConditionsModal(event)} style={{ color: textColor }}>
            <Translation id="view_conditions" defaultMessage="View conditions" />
        </button>
    )

    const cardMessages = {
        titleFivePercent: intl.formatMessage({ id: 'extra_discount_half_trucks', defaultMessage: 'extra discount half trucks' }),
        titleTenPercent: intl.formatMessage({ id: 'extra_discount_full_trucks', defaultMessage: 'extra discount full trucks' })
    }

    return (
        <React.Fragment>
            <div className="banner-silestone-dekton-blackweek" style={{ backgroundColor: backgroundColor }}>
                <div className="container p-0">
                    <div className={`banner__container ${layout === 'column' ? 'banner__container--column' : ''}`}>
                        <div className={`banner__header ${layout === 'row' ? 'width-50' : ''}`}>
                            <div className="banner__title">
                                <h2 style={{ color: textColor, fontSize: titleFontSize }}>
                                    <Translation id="silestone_dekton_blackweek_promo" defaultMessage="Silestone+Dekton “Black Week” promo" />
                                </h2>
                                <h3 style={{ color: textColor, fontSize: titleFontSize }}>
                                    <Translation id="from_november_25_to_29" defaultMessage="from November 25 to 29." />
                                </h3>
                            </div>
                            {showConditionsButtonCatalog && <div className="banner__link">{checkConditionsBtn}</div>}
                        </div>
                        <div className={`banner__content ${layout === 'row' ? 'width-50' : ''}`}>
                            <div className={`banner__cards ${layout === 'column' ? 'banner__cards--column' : ''}`}>
                                <Card
                                    percentAmount={'5'}
                                    percentText={cardMessages.titleFivePercent}
                                    className="banner__card"
                                    textColor={textColor}
                                    borderColor={borderColor}
                                    percentFontSize={percentFontSize}
                                />
                                <Card
                                    percentAmount={'10'}
                                    percentText={cardMessages.titleTenPercent}
                                    className="banner__card"
                                    textColor={textColor}
                                    borderColor={borderColor}
                                    percentFontSize={percentFontSize}
                                />
                            </div>
                            <div className="banner__details" style={{ color: textColor }}>
                                <p className="banner__detail">
                                    <Translation
                                        id="silestone_applies_on_top_of_existing_conditions_collective_deals_individual_deals"
                                        defaultMessage="Silestone: Applies on top of existing conditions (collective deals, individual deals, builder programs, other promotions, …etc), except one-off project prices."
                                    />
                                </p>
                                <p className="banner__detail">
                                    <Translation
                                        id="dekton_applies_only_over_standard_volume_discounts_so_it_will_not_apply_on_top_of_the_other_conditions"
                                        defaultMessage="Dekton: Applies only over standard / volume discounts, so it will not apply on top of the other conditions (collective deals, individual deals, builder programs, other promotions, … etc), nor one-off project prices."
                                    />
                                </p>
                                <p className="banner__detail pt-2">
                                    <Translation id="for_slab_orders_only" defaultMessage="For slab orders only." />
                                </p>
                            </div>

                            {showConditionsButtonCatalog && <div className="banner__link-mobile">{checkConditionsBtn}</div>}

                            {showConditionsButtonCheckout && <div className="banner__link--row">{checkConditionsBtn}</div>}
                        </div>
                    </div>
                </div>
            </div>
            {showConditionsModal &&
                ReactDOM.createPortal(
                    <Modal
                        title={<Translation id="silestone_dekton_blackweek_promo" defaultMessage="Silestone+Dekton “Black Week” promo" />}
                        onClose={event => handleCloseConditionsModal(event)}
                        customClass={'silestone-dekton-blackweek-modal'}
                    >
                        <div>
                            <h3 className="modal-subtitle">
                                <Translation id="conditions" defaultMessage="Conditions" />
                            </h3>
                            <ul style={{ fontWeight: 400 }}>
                                <li>
                                    <span style={{ fontWeight: 500 }}>
                                        <Translation id="applicable_discounts" defaultMessage="Applicable discounts" />
                                    </span>
                                    :
                                    <ul>
                                        <li>
                                            <Translation
                                                id="silestone_applies_on_top_of_existing_conditions_collective_deals_individual_deals"
                                                defaultMessage="Silestone: Applies on top of existing conditions (collective deals, individual deals, builder programs, other promotions, …etc), except one-off project prices."
                                            />
                                        </li>
                                        <li>
                                            <Translation
                                                id="dekton_applies_only_over_standard_volume_discounts_so_it_will_not_apply_on_top_of_the_other_conditions"
                                                defaultMessage="Dekton: Applies only over standard / volume discounts, so it will not apply on top of the other conditions (collective deals, individual deals, builder programs, other promotions, … etc), nor one-off project prices."
                                            />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 500 }}>
                                        <Translation id="minimum_orders" defaultMessage="Minimum orders" />
                                    </span>
                                    :
                                    <ul>
                                        <li>
                                            <Translation
                                                id="only_half_truck_12_tons_full_truck_24_tons_or_full_container_orders_placed"
                                                defaultMessage="Only half truck (12 tons), full truck (24 tons) or full container orders placed with Cosentino Global Spain are accepted."
                                            />
                                        </li>
                                        <li>
                                            <Translation
                                                id="rule_for_slabs_all_orders_must_contain_at_least_silestone_slabs"
                                                defaultMessage="1:1 rule for slabs: All orders must contain at least 50% Silestone slabs. 100% Silestone orders are possible."
                                            />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 500 }}>
                                        <Translation id="delivery_dates_and_conditions" defaultMessage="Delivery dates and conditions" />
                                    </span>
                                    :
                                    <ul>
                                        <li>
                                            <Translation
                                                id="orders_must_be_received_between_november_25_and_29"
                                                defaultMessage="Orders must be received between November 25 and 29 (by 5:00 p.m.)."
                                            />
                                        </li>
                                        <li>
                                            <Translation id="2024_prices_will_apply" defaultMessage="2024 prices will apply." />
                                        </li>
                                        <li>
                                            <Translation id="delivery_must_be_completed_by_january" defaultMessage="Delivery must be completed by January 31, 2025." />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 500 }}>
                                        <Translation id="terms_of_payment" defaultMessage="Terms of payment" />
                                    </span>
                                    :
                                    <ul>
                                        <li>
                                            <Translation id="payments_at_30_60_90_120_150_and_180_days" defaultMessage="Payments at 30, 60, 90, 120, 150 and 180 days." />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 500 }}>
                                        <Translation id="bonus_objectives" defaultMessage="Bonus objectives" />
                                    </span>
                                    :
                                    <ul>
                                        <li>
                                            <Translation
                                                id="orders_will_count_towards_the_year_end_rebate_target"
                                                defaultMessage="Orders will count towards the year-end rebate target if delivered within 2024, but we will be paid a rebate on these orders."
                                            />
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Modal>,
                    document.getElementById('wrapper-contain')
                )}
        </React.Fragment>
    )
}

const Card = ({ percentAmount, percentText, className, textColor, borderColor, percentFontSize }) => (
    <div className={className}>
        <div>
            <h5 style={{ color: textColor, borderColor: borderColor, fontSize: percentFontSize }}>{percentAmount}%</h5>
        </div>
        <div>
            <h6 style={{ color: textColor, borderColor: borderColor }}>{percentText}</h6>
        </div>
    </div>
)

export default injectIntl(BannerSilestoneDektonBlackWeek)
