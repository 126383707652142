import React from 'react'
import { connect } from 'react-redux'
import { getLanguage } from '../../../store/ui/reducers'
import silestoneXMLogo from '../../../assets/img/banner-silestone-xm/logo-silestone-xm.svg'
import hybriqMineralInsideLogo from '../../../assets/img/banner-silestone-xm/logo-hybriq-mineral-inside.png'
import silestoneXMBackgroundImg from '../../../assets/img/banner-silestone-xm/silestone-xm-background-img.png'
import Button from '../../global/Button'
import Translation from '../../global/Translation'
import { FormattedHTMLMessage } from 'react-intl'

const mapStateToProps = state => {
    return {
        language: getLanguage(state)
    }
}

class BannerSilestoneXM extends React.Component {
    render() {
        const { language } = this.props

        const links = {
            da: 'https://www.cosentino.com/da-dk/silestone-xm/',
            de: 'https://www.cosentino.com/de/silestone-xm/',
            en: 'https://www.cosentino.com/silestone-xm/',
            es: 'https://www.cosentino.com/es/silestone-xm/',
            fi: 'https://www.cosentino.com/fi-fi/silestone-xm/',
            fr: 'https://www.cosentino.com/fr-fr/silestone-xm/',
            hu: 'https://www.cosentino.com/silestone-xm/',
            it: 'https://www.cosentino.com/it-it/silestone-xm/',
            ja: 'https://www.cosentino.com/ja-jp/silestone-xm/',
            nl: 'https://www.cosentino.com/nl-nl/silestone-xm/',
            no: 'https://www.cosentino.com/no-no/silestone-xm/',
            pl: 'https://www.cosentino.com/pl-pl/silestone-xm/',
            pt: 'https://www.cosentino.com/pt-pt/silestone-xm/',
            sv: 'https://www.cosentino.com/sv-se/silestone-xm/',
            tr: 'https://www.cosentino.com/tr-tr/silestone-xm/',
            he: 'https://www.cosentino.com/silestone-xm/',
            'en-US': 'https://www.cosentino.com/silestone-xm/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone-xm/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone-xm/'
        }

        const hybriqMineralLogoBlock = <img src={hybriqMineralInsideLogo} alt="Hybriq Mineral Inside" />

        const backgroundImageStyle = {
            backgroundImage: `url(${silestoneXMBackgroundImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }

        return (
            <div className="banner-silestone-xm-health-safety" style={backgroundImageStyle}>
                <div className="banner">
                    <div className="banner__header">
                        <div className="banner__logo">
                            <div className="silestone-xm-logo">
                                <img src={silestoneXMLogo} alt="Silestone XM" />
                            </div>
                            <div className="hybriq-mineral-logo">{hybriqMineralLogoBlock}</div>
                        </div>
                        <div className="banner__content">
                            <div className="banner__text">
                                <h4>
                                    <Translation
                                        id="silestonexm_is_our_design_mineral_surface_with_a_maximum_content"
                                        defaultMessage="Silestone XM is our design mineral surface with a maximum content of 10% crystalline Silica, embracing the entire Q10 range of Silestone."
                                    />
                                </h4>
                            </div>
                            <div className="banner__image">{hybriqMineralLogoBlock}</div>
                        </div>
                        <div className="banner__button">
                            <a href={links[language]} target="_blank" rel="noopener noreferrer">
                                <Button className="cta-silestone-xm" inline={true} gtmLabel={"landing_silestone_xm_know_more"} gtmUsertype={'m'}>
                                    <Translation id="get_to_know_Silestonexm" defaultMessage="Get to know Silestone® XM" />
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div className="banner__footer">
                        <div className="banner__footer-text">
                            <p>
                                <FormattedHTMLMessage
                                    id="access_comprehensive_health_and_safety_information"
                                    defaultMessage="Access comprehensive <b>Health & Safety information</b> for our Cosentino products to ensure safe and responsible use."
                                />
                            </p>
                        </div>
                        <div className="banner__footer-button">
                            <a href={'https://osh.cosentino.com/health-safety-documentation/safety-data-sheets-sds/'} target="_blank" rel="noopener noreferrer">
                                <Button inverted className="cta-health-safety-info" gtmLabel={"landing_silestone_xm_health_and_safety"} gtmUsertype={'m'}>
                                    <Translation id="go_to_health_and_safety_information" defaultMessage="Go to Health & Safety information" />
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(BannerSilestoneXM)
