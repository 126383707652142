import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from '../../global/Modal'
import Translation from '../../global/Translation'
import { toggleHealthConditionsCheck } from '../../../store/checkout/actions'
import { canSendCheckoutHealthConditions } from '../../../store/checkout/reducers'
import Button from '../../global/Button'
import { FormattedHTMLMessage } from 'react-intl'
import Alerts from '../../alerts/Alerts'

const mapStateToProps = state => {
    return {
        canSendCheckoutHealthConditions: canSendCheckoutHealthConditions(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleHealthConditionsCheck: checked => {
            dispatch(toggleHealthConditionsCheck(checked))
        }
    }
}

const ModalLinkHealthConditions = props => {
    const { onConfirm, onCancel, toggleHealthConditionsCheck, canSendCheckoutHealthConditions } = props

    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    const [scrollPercentage, setScrollPercentage] = useState(0.8)
    const modalContentRef = useRef(null)

    const updateScrollPercentage = () => {
        if (window.innerWidth <= 991) {
            setScrollPercentage(0.6)
        } else {
            setScrollPercentage(0.8)
        }
    }

    const handleScroll = () => {
        const content = modalContentRef.current
        const currentScrollPercentage = content.scrollTop / (content.scrollHeight - content.clientHeight)

        // If already set to true, do not change back to false
        if (currentScrollPercentage >= scrollPercentage && !isScrolledToBottom) {
            setIsScrolledToBottom(true)
        }
    }

    useEffect(() => {
        updateScrollPercentage()
        window.addEventListener('resize', updateScrollPercentage)

        const content = modalContentRef.current
        if (content) {
            content.addEventListener('scroll', handleScroll)
        }

        // Cleanup
        return () => {
            if (content) {
                content.removeEventListener('scroll', handleScroll)
            }
            window.removeEventListener('resize', updateScrollPercentage)
        }
    }, [scrollPercentage])

    return (
        <div className="health-conditions-modal">
            <Modal
                title={<Translation id="health_and_safety_information" defaultMessage="Health & safety information" />}
                onClose={onCancel}
                showCloseButton={false}
                subtitle={
                    <div className="mt-3">
                        <Alerts status="primary" alert={'health_safety_information_scroll_down'} cssIcon="fas fa-exclamation-triangle" iconSize="16px" className={"health-conditions-modal-alert"}/>
                    </div>
                }
                footer={
                    <div className="health-conditions-modal__actions">
                        <div>
                            <label className="CheckboxLinkSaleConditions" data-qas="checkout_steptwo-checkbox-health-conditions-checkbox">
                                <input
                                    name="health_conditions_check"
                                    type="checkbox"
                                    disabled={!isScrolledToBottom}
                                    onClick={event => toggleHealthConditionsCheck(event.target.checked)}
                                />
                                <span className="checkmark" style={{ borderColor: isScrolledToBottom ? '#000000' : '#7D7D7D' }} />
                                <span style={{ color: isScrolledToBottom ? '#000000' : '#7D7D7D' }}>
                                <FormattedHTMLMessage 
                                    id="health_safety_conditions_checkbox_message" 
                                    defaultMessage="I hereby confirm to have read and to agree to the above terms, acknowledging having read also the <a href='https://osh.cosentino.com/health-safety-documentation/safety-data-sheets-sds/' target='_blank' rel='noopener noreferrer'>safety data sheets</a> of the products purchased together with all the related safety information available in the <a href='https://osh.cosentino.com' target='_blank' rel='noopener noreferrer'>Cosentino Safety Space</a>, and I commit to sharing this information with any worker or contractor that will ultimately process these products."
                                />
                                </span>
                            </label>
                        </div>
                        <div className="modal__actions-btn">
                            <Button onClick={onCancel}>
                                <Translation id="cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button onClick={onConfirm} disabled={!canSendCheckoutHealthConditions} inverted>
                                <Translation id="accept" defaultMessage="Accept" />
                            </Button>
                        </div>
                    </div>
                }
                ref={modalContentRef}
            >
                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_title"
                        defaultMessage="READ THE FOLLOWING CAREFULLY. BY ACCEPTING BELOW, YOU, REFERRED TO HEREIN AS 'COSTUMER', ARE EXPLICITLY AGREEING TO THE TERMS SET OUT HEREIN, TO ANY
                    DOCUMENTS INCORPORATED HERETO BY REFERENCE, AND TO ANY LINKED DOCUMENTS INCLUDED IF/ WHEN APPLICABLE."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_1"
                        defaultMessage="Cosentino slab products (“Cosentino Products”) contain varying concentrations of crystalline silica. It is crucial for the Customer to understand that cutting, drilling, polishing, or grinding Cosentino Products, especially through uncontrolled dry cutting, emits airborne particles, including respirable crystalline silica. Inhalation of crystalline silica is associated with an increased risk of pneumoconiosis, pulmonary fibrosis (silicosis), lung cancer, chronic obstructive pulmonary disease (COPD), autoimmune diseases, and kidney diseases. Cosentino hereby warns the Customer that processing Cosentino Products must be done using dust-mitigating engineering controls such as wet methods, local exhaust, and general ventilation, or any other proven methods to minimize the generation and inhalation of respirable crystalline silica and dust, in accordance with existing applicable regulations. Cosentino strongly advises the use of personal respiratory equipment with efficient particle filters (type P3, N95, or superior)."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_2"
                        defaultMessage="All Cosentino Products come with safety labels indicating the crystalline silica content and the safety warnings. For additional information to ensure the safe handling of Cosentino Products, Customer, their employees, and subcontractors shall refer to the applicable regulations, good practice guides, and material safety data sheets available at Cosentino Safety Space on Cosentino's website: <a href='https://osh.cosentino.com' target='_blank' rel='noopener noreferrer'>https://osh.cosentino.com</a>. It is important that the Customer communicates this information to its employees, subcontractors, and agents who will ultimately process Cosentino Products."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_3"
                        defaultMessage="□ Warnings acknowledgement. Customer represents and warrants that it has received, read and understood at least the following safety documents, available at least under <a href='https://osh.cosentino.com' target='_blank' rel='noopener noreferrer'>https://osh.cosentino.com</a>:"
                    />
                </p>

                <ul>
                    <li>
                        <span>
                            <FormattedHTMLMessage id="health_safety_conditions_message_content_4" defaultMessage="Safety Labels of purchased Cosentino products." />
                        </span>
                    </li>
                    <li>
                        <span>
                            <FormattedHTMLMessage
                                id="health_safety_conditions_message_content_5"
                                defaultMessage="Material Safety Data Sheets of purchased Cosentino products."
                            />
                        </span>
                    </li>
                    <li>
                        <span>
                            <FormattedHTMLMessage
                                id="health_safety_conditions_message_content_6"
                                defaultMessage="Good Practices Guide o Health and Safety Guide for Installers."
                            />
                        </span>
                    </li>
                    <li>
                        <span>
                            <FormattedHTMLMessage
                                id="health_safety_conditions_message_content_7"
                                defaultMessage="Other guides and manuals posted in the Cosentino Safety Space Customer agrees that it has reviewed and will follow the requirements set forth in the above documents."
                            />
                        </span>
                    </li>
                </ul>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_8"
                        defaultMessage="The Customer will disseminate these requirements and associated risks to its employees, subcontractors, agents, customers, fabricators, and any other purchasers or users of Cosentino Products. The safety documents mentioned above do not substitute the legal obligations applicable to the Customer regarding health and safety under local regulations. The Customer must always seek advice from the relevant authorities and professional industrial hygienists to implement the necessary occupational safety measures to meet regulatory obligations and mitigate dust exposure. The required safety measures depend on the specific conditions of each workplace, and Cosentino's guidance and recommendations does not make Cosentino liable for such advice. Customer agrees that Cosentino Products contain safety warning labels as required by applicable laws and regulations. If the Customer receives a Cosentino Product without the required warning label, they will immediately notify Cosentino in writing at info@cosentino.com within three (3) days of receipt."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_9"
                        defaultMessage="□ General requirements. Customer has an ongoing obligation to ensure best practices are followed during the handling, storage, fabrication, installation, and disposal of Cosentino Products. This includes informing and training all employees and installers, including third-party subcontracted installers, on these best practices. The Customer will prioritize the safety of Cosentino's customers and their own personnel at all times, adhering to all applicable safety guidelines, environmental laws, and procedures. These procedures must conform to those required or recommended by federal, state, national, and local governmental or quasi-governmental authorities with jurisdiction over the fabrication activities. This includes, but is not limited to, all applicable guidelines and regulations issued by the federal Occupational Safety and Health Administration (OSHA) and any relevant national and state agencies."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_10"
                        defaultMessage="□ Silica Related Requirements. THE CUSTOMER UNDERSTANDS AND WILL ENSURE THAT ITS EMPLOYEES, SUBCONTRACTORS, AGENTS, CUSTOMERS, AND/OR FABRICATORS UNDERSTAND THAT CHIPPING, DRILLING, POLISHING, OR GRINDING OF COSENTINO PRODUCTS, ESPECIALLY THROUGH DRY CUTTING, EMITS AIRBORNE PARTICLES, INCLUDING CRYSTALLINE SILICA. INHALATION OF THESE PARTICLES MAY CAUSE SILICOSIS OR OTHER OCCUPATIONAL ILLNESSES OR HEALTH CONDITIONS. COSENTINO REQUIRES THAT FABRICATORS OF COSENTINO PRODUCTS UTILIZE WET CUTTING, WET GRINDING, WET MILLING, WET POLISHING, TOGETHER WITH ANY OTHER APPROPRIATE METHODS TO PREVENT THE RISK OF INHALATION OF AIRBORNE PARTICLES AND RESPIRATORY ILLNESSES. FABRICATORS ARE RESPONSIBLE FOR ENSURING THE SAFETY OF THEIR EMPLOYEES AT THE WORKSITE, AND MUST TRAIN THEM AND IMPLEMENT ALL APPROPRIATE SAFETY MEASURES TO PREVENT EXPOSURE TO SILICA DUST OR ANY OTHER HAZARDS."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_11"
                        defaultMessage="□ Installation site. The Customer, along with any fabricator and installer under their direction, must ensure that all necessary mechanical processing, particularly cutting and polishing of the Cosentino Product, is performed at the Fabricator's site."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_12"
                        defaultMessage="This should be avoided at the end customer's site during installation. However, if minor modifications are unavoidable, dust emissions during processing must be minimized, for example, by using wet methods. If wet processing is not feasible at the end customer's installation site, the Customer, fabricator and installer should use hand tools equipped with efficient local exhaust ventilation (LEV) systems to suppress exposure to airborne particles of dust and respirable crystalline silica. The Customer is advised to review the Health & Safety Guide for installers, available at <a href='https://osh.cosentino.com' target='_blank' rel='noopener noreferrer'>https://osh.cosentino.com</a>."
                    />
                </p>

                <p>
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_13"
                        defaultMessage="□ Waste disposal. Waste generated from processing Cosentino Products contains crystalline silica. It needs to be handled, stored and disposed avoiding dust particles getting airborne. All waste that is generated by the fabrication of Cosentino Products or accessory chemicals that are otherwise used by Customer or fabricator, if applicable, must be properly managed, stored, transported, and disposed of in accordance with applicable local, state, and federal laws, rules, and regulations."
                    />
                </p>

                <p className="health-conditions-modal-last-para">
                    <FormattedHTMLMessage
                        id="health_safety_conditions_message_content_14"
                        defaultMessage="By accepting below, and on behalf of the Customer, I acknowledge that the Customer has read and understood the above information and requirements. The Customer is aware of and complies with all applicable laws and regulations. I also represent and warrant that since the Customer first purchased Cosentino Products, Costumer have recurrently received and been aware of the safety information regarding crystalline silica and other risks associated with processing Cosentino Products, as provided in Cosentino's safety labels, safety data sheets, and good practice guides, both at the time of each purchase and as updated periodically."
                    />
                </p>
            </Modal>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLinkHealthConditions)
