export const getFinishOptions = (props, state) => {
    if (state.selectedColor && props.secondaryParams && props.secondaryParams.relations) {
        let filtered = props.secondaryParams.relations.filter((p) => {
            let check = true;
            if (state.selectedFormat) check = check && p.format === state.selectedFormat
            if (state.selectedThickness) check = check && p.thickness === state.selectedThickness
            if (state.selectedAddon) check = check && p.addon === state.selectedAddon
            return check;
        })
        const rawFinish = [...new Set(filtered.map(v => v.finish))]

        return rawFinish.map(finished => {
            const label = props.intl.formatMessage({ id: finished })
            return {
                label,
                value: finished
            }
        })
    }
    return null
}

export const getThicknessOptions = (props, state) => {
    if (state.selectedColor && props.secondaryParams && props.secondaryParams.relations) {
        let filtered = props.secondaryParams.relations.filter((p) => {
            let check = true;
            if (state.selectedFormat) check = check && p.format === state.selectedFormat
            if (state.selectedFinished) check = check && p.finish === state.selectedFinished
            if (state.selectedAddon) check = check && p.addon === state.selectedAddon
            return check;
        })
        const rawThickness = [...new Set(filtered.map(v => v.thickness))].sort()

        return rawThickness.map(thickness => {
            return {
                label: thickness + ' cm',
                value: thickness
            }
        })
    }
    return null
}

export const getAddonOptions = (props, state) => {
    if (state.selectedColor && props.secondaryParams && props.secondaryParams.relations) {

        let filtered = props.secondaryParams.relations.filter((p) => {
            let check = true;
            if (state.selectedFormat) check = check && p.format === state.selectedFormat
            if (state.selectedFinished) check = check && p.finish === state.selectedFinished
            if (state.selectedThickness) check = check && p.thickness === state.selectedThickness
            return check;
        })
        const rawAddons = [...new Set(filtered.map(v => v.addon))]
        return rawAddons.map(addon => {
            return {
                label: addon === 'MAL' ? props.intl.formatMessage({ id: 'with_mesh' }) : props.intl.formatMessage({ id: 'without_mesh' }),
                value: addon
            }
        })
    }
    return null
}