import { connect } from 'react-redux'
import {
    getShippingMethod,
    getHasMinWeight,
    getSelectedCenter,
    isEuropeOrIberia,
    canOffer,
    getNaturalStoneSpainCenterId,
    getLatinaVitoriaCenterId
} from '../../../store/centers/reducers'
import { checkout, transformSkuToLots, setShowModalNoStock, removeCartLine } from '../../../store/cart/actions'
import {
    getAlert,
    getGoalMax,
    getGoalMin,
    getIsCartCutToSize,
    getHaveSomeProductsBelowThreshold,
    getHaveSomeProductsWithoutSapStock,
    getKgPoundRatio,
    getMonthItems,
    getReservationCode,
    getReservationCutToSizeCode,
    getReservationConfigurableCode,
    getShowModalNoStock,
    getStatus,
    getTransformingSkuToLots,
    getWaitTillDate,
    isMallCartForContainer,
    isSending,
    qtySelector,
    weightSelector
} from '../../../store/cart/reducers'
import { getLanguage, getShowHealthConditions } from '../../../store/ui/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import { getEndiSelectedShippingAddressId, getShippingAddresses, isLoadingShippingAddress } from '../../../store/shipping-addresses/reducers'
import { fetchShippingAddresses } from '../../../store/shipping-addresses/actions'
import {
    canSendMinMaxCheckout,
    canSendNaturalStoneContainer,
    canSendNeedDestination,
    changeShippingAddress,
    resetCheckout,
    setComments,
    setCountryOfSend,
    setCutToSizeFile,
    setDeliveryDate,
    setDestination,
    setEmployeeId,
    setHasSelectedCenter,
    setPo,
    setSelectedShippingAddress,
    setSelectedShippingAddressEcosentinoKey,
    setSelectedShippingAddressId,
    setSelectedShippingAddressSalesOrg,
    setSelectedShippingCenterId
} from '../../../store/checkout/actions'
import {
    getDeliveryDate,
    getSelectedShippingAddress,
    getDestination,
    getMinRequiredDate,
    canSendCheckoutSaleConditions,
    canSendCheckoutHealthConditions,
    getPo,
    getSaleConditionsCheck,
    getHealthConditionsCheck,
    canSendCheckoutAustraliaLegalProcessConditions
} from '../../../store/checkout/reducers'
import { getEmployeeId } from '../../../store/big-account/reducers'
import { isEmserUser } from '../../../store/login/reducers'
import CheckoutFormCustomerV2 from './CheckoutFormCustomerV2'
import React from 'react'
import { dispatchPushURL } from '../../../store/ui/actions'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit 

    return {
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMin: getGoalMin(state, unit),
        goalMax: getGoalMax(state, unit),
        shippingAddresses: getShippingAddresses(state),
        hasDestination: getShippingMethod(state) === 'CONTAINER',
        shippingMethod: getShippingMethod(state),
        alert: getAlert(state),
        status: getStatus(state),
        monthItems: getMonthItems(state),
        waitTillDate: getWaitTillDate(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        hasMinWeight: getHasMinWeight(state),
        centerSelected: getSelectedCenter(state),
        haveCutToSize: getIsCartCutToSize(state),
        isEuropeOrIberia: isEuropeOrIberia(state),
        isLoadingShippingAddress: isLoadingShippingAddress(state),
        deliveryDate: getDeliveryDate(state),
        minDeliveryDate: getMinRequiredDate(state),
        selectedShippingAddress: getSelectedShippingAddress(state),
        destination: getDestination(state),
        haveSomeProductsBelowThreshold: getHaveSomeProductsBelowThreshold(state),
        haveSomeProductsWithoutSapStock: getHaveSomeProductsWithoutSapStock(state),
        canSendCheckoutSaleConditions: canSendCheckoutSaleConditions(state),
        canSendCheckoutHealthConditions: canSendCheckoutHealthConditions(state),
        isOffer: canOffer(state),
        isMallCartForContainer: isMallCartForContainer(state),
        employeeId: getEmployeeId(state),
        showModalNoStock: getShowModalNoStock(state),
        naturalStoneSpainCenterId: getNaturalStoneSpainCenterId(),
        latinaVitoriaCenterId: getLatinaVitoriaCenterId(),
        transformingSkuToLots: getTransformingSkuToLots(state),
        isEmserUser: isEmserUser(state),
        po: getPo(state),
        reservationCutToSizeCode: getReservationCutToSizeCode(state),
        reservationConfigurableCode: getReservationConfigurableCode(state),
        hasSaleConditionsCheck: getSaleConditionsCheck(state),
        hasHealthConditionsCheck: getHealthConditionsCheck(state),
        endiSelectedShippingAddressId: getEndiSelectedShippingAddressId(state),
        getSelectedShippingAddress: getSelectedShippingAddress(state),
        getDestination: getDestination(state),
        canSendCheckoutAustraliaLegalProcessConditions: canSendCheckoutAustraliaLegalProcessConditions(state),
        showHealthConditions: getShowHealthConditions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        changeShippingAddress: value => dispatch(changeShippingAddress(value)),
        canSendMinMaxCheckout: () => dispatch(canSendMinMaxCheckout()),
        canSendNeedDestination: () => dispatch(canSendNeedDestination()),
        canSendNaturalStoneContainer: () => dispatch(canSendNaturalStoneContainer()),
        resetCheckout: () => dispatch(resetCheckout()),
        setCountryOfSend: value => dispatch(setCountryOfSend(value)),
        setSelectedShippingCenterId: value => dispatch(setSelectedShippingCenterId(value)),
        setSelectedShippingAddressId: value => dispatch(setSelectedShippingAddressId(value)),
        setSelectedShippingAddress: value => dispatch(setSelectedShippingAddress(value)),
        setSelectedShippingAddressEcosentinoKey: value => dispatch(setSelectedShippingAddressEcosentinoKey(value)),
        setSelectedShippingAddressSalesOrg: value => dispatch(setSelectedShippingAddressSalesOrg(value)),
        setDestination: value => dispatch(setDestination(value)),
        setPo: value => dispatch(setPo(value)),
        setCutToSizeFile: value => dispatch(setCutToSizeFile(value)),
        setComments: value => dispatch(setComments(value)),
        submitCheckout: () => dispatch(checkout(true)),
        setEmployeeId: value => dispatch(setEmployeeId(value)),
        setHasSelectedCenter: value => dispatch(setHasSelectedCenter(value)),
        transformSkuToLots: () => {
            return dispatch(transformSkuToLots())
        },
        setOfferMode: () => dispatch({ type: 'CHECKOUT_HAVE_OFFER' }),
        closeNoStockModal: () => dispatch(setShowModalNoStock(false)),
        showNoStockModal: () => dispatch(setShowModalNoStock(true)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
    }
}

const CheckoutFormCustomerDesign = React.forwardRef((props, ref) => <CheckoutFormCustomerV2 ref={ref} {...props} />)

const CheckoutFormCustomerContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CheckoutFormCustomerDesign)

export default CheckoutFormCustomerContainer
