import React, { createRef } from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import {cloneDeep, values} from 'lodash'
import Slider from "react-slick";
import { defineMessages } from 'react-intl'

defineMessages({
    XERON: {
        id: "xeron",
        description: "xeron",
        defaultMessage: "Xeron"
    },
    special_custom_projects: {
        id: "special_custom_projects",
        description: "special_custom_projects",
        defaultMessage: "Special custom projects"
    },
    custom_flooring: {
        id: "custom_flooring",
        description: "custom_flooring",
        defaultMessage: "Custom flooring"
    },
    wall_clading: {
        id: "wall_clading",
        description: "wall_clading",
        defaultMessage: "Wall clading"
    },
    baseboards: {
        id: "baseboards",
        description: "baseboards",
        defaultMessage: "Baseboards"
    },
    prexury: {
        id: "prexury",
        description: "prexury",
        defaultMessage: "Prexury"
    },
    scalea: {
        id: "scalea",
        description: "scalea",
        defaultMessage: "Scalea"
    },
    vanities: {
        id: "vanities",
        description: "vanities",
        defaultMessage: "Vanities"
    },
    sensa: {
        id: "sensa",
        description: "sensa",
        defaultMessage: "Sensa"
    },
    colorsil: {
        id: "colorsil",
        description: "colorsil",
        defaultMessage: "Colorsil"
    },
    flooring_cladding: {
        id: "flooring_cladding",
        description: "flooring_cladding",
        defaultMessage: "Flooring cladding"
    },
})

class MenuLinksV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            props: props,
        }
    }

    render() {
        const {
            responsive,
            customClass,
            mktoolShippingCenter
        } = this.state.props

        const showMall = () => {
            let control = false
            if(this.props.userLandings && this.props.userLandings.length > 0 && this.props.isMallPilotUser) {
                this.props.userLandings.map(landing => {
                    let today = new Date()
                    if (new Date(landing.end_at) > today) {
                        control = true
                    }

                })
                return(control)
            }
            return false
        }
        const getMallLink = () => {
            if(this.props.userLandings && this.props.userLandings.length > 0) {
                for (let i = 0; i < this.props.userLandings.length; i++) {
                    let today = new Date()
                    if (new Date(this.props.userLandings[i].end_at) > today) {                                             
                        return (this.props.userLandings[i].slug)                        
                    }                    
                }                
            }else{
                return ("test")  
            }
            
        }

        const brandLinks = [];
        if (
            !this.props.isNoSilestoneCenter && // Verifica si no es un centro que no vende Silestone
            (!this.props.isAustralianCenter || this.props.isSilestoneBrandVisible) // Verifica si no es Australia o si hay stock
        ) {
            brandLinks.push({
                name: 'Silestone',
                queryString: `?type[0]=TABLA&brand[0]=SILESTONE`,
                routeId: 'ROUTE_CATALOG',
                translationId: 'silestone',
                params: { outlet: '' },
                dataQas: 'global_header-silestone-menu-link-child'
            })
        }

        if (this.props.isAustralianCenter) {
            brandLinks.push({
                name: 'Xeron',
                queryString: `?type[0]=TABLA&brand[0]=XERON`,
                routeId: 'ROUTE_CATALOG',
                translationId: 'xeron',
                params: { outlet: '' },
                dataQas: 'global_header-xeron-menu-link-child'
            })
        }
        
        brandLinks.push({
            name: this.props.selectedCenter == 7130 ? 'DKTN' : 'Dekton',
            translationId: this.props.selectedCenter == 7130 ? 'Dktn' : 'dekton',
            queryString:
                this.props.selectedCenter == 7130
                    ? `?type[0]=TABLA&brand[0]=DKTN`
                    : `?type[0]=TABLA&brand[0]=DEKTON`,
            routeId: 'ROUTE_CATALOG',
            params: { outlet: '' },
            dataQas: 'global_header-dekton-menu-link-child'
        })
        brandLinks.push({
            name: 'Scalea',
            translationId: 'scalea',
            queryString: `?type[0]=TABLA&brand[0]=SCALEA`,
            routeId: 'ROUTE_CATALOG',
            params: { outlet: '' },
            dataQas: 'global_header-scalea-menu-link-child'
        })
        brandLinks.push({
            name: 'Sensa',
            translationId: 'sensa',
            queryString: `?type[0]=TABLA&brand[0]=SENSA`,
            routeId: 'ROUTE_CATALOG',
            params: { outlet: '' },
            dataQas: 'global_header-sensa-menu-link-child'
        })
        brandLinks.push({
            name: 'Prexury',
            translationId: 'prexury',
            queryString: `?type[0]=TABLA&brand[0]=PREXURY`,
            routeId: 'ROUTE_CATALOG',
            params: { outlet: '' },
            dataQas: 'global_header-prexury-menu-link-child'
        })

        const outletBrandLinks = cloneDeep(brandLinks)
        outletBrandLinks.pop()
        outletBrandLinks.forEach(v => {
            v.params.outlet = 'outlet'
            v.queryString = v.queryString.replace('type[0]=TABLA&', '')
            delete v.dataQas
        })

        let linksMenu = [
            {
                name: 'slabs',
                routeId: 'ROUTE_CATALOG',
                params: { outlet: '' },
                queryString: '?type[0]=TABLA',
                translationId: 'tables',
                customClassTag: 'slabs',
                show: this.props.centerPreferences && this.props.centerPreferences.productsByType.slab && this.props.canViewCatalog,
                dataQas: 'global_header-slabs-menu-link',
                gtmLabel: 'ec_menu_header-slabs',
                links: brandLinks
            },
            {
                name: 'standard products',
                routeId: 'ROUTE_CATALOG',
                queryString: '?type[0]=TABLA&type[1]=CTS&subtype[0]=SOLERIA&type[2]=BAÑOS&type[3]=FREGADERO&subtype[1]=Optimma&subtype[2]=Washbasin&subtype[3]=wall_clading&subtype[4]=Shower%20tray&subtype[5]=IDUEL&subtype[6]=IDUES&subtype[7]=INTEGRITY%20DUE%20L&subtype[8]=INTEGRITY%20DUE%20S&subtype[9]=INTEGRITY%20DUE-T&subtype[10]=INTEGRITY%20ONE&subtype[11]=INTEGRITY%20XL&subtype[12]=INTEGRITY-Q&subtype[13]=IONE&subtype[14]=IXL&subtype[15]=LOF&subtype[16]=Tile',
                params: { outlet: '' },
                translationId: 'standard_products',
                customClassTag: 'standard',
                show: this.props.canViewCatalog && !this.props.canViewMktools,
                dataQas: 'global_header-standard-products-menu-link',
                gtmLabel: 'ec_menu_header-sinks',
                links: [
                    {
                        name: 'Flooring & Cladding',
                        queryString: '?type[0]=TABLA&type[1]=CTS&subtype[0]=SOLERIA&subtype[1]=Optimma&subtype[2]=wall_clading&subtype[3]=Tile',
                        routeId: 'ROUTE_CATALOG',
                        translationId: 'flooring_cladding',
                        params: { outlet: '' },
                        dataQas: 'global_header-flooring-cladding-menu-link-child'
                    },
                    {
                        name: 'Sinks',
                        queryString: '?type[0]=FREGADERO',
                        translationId: 'sinks',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-sinks-menu-link-child'
                    },
                    {
                        name: 'Shower Trays',
                        queryString: '?type[0]=BAÑOS&subtype[0]=Shower%20tray',
                        translationId: 'subtype_shower_tray',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-shower-trays-menu-link-child'
                    },
                    {
                        name: 'Vanities',
                        translationId: 'vanities',
                        queryString: '?type[0]=BA%C3%91OS&subtype[0]=Washbasin',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-vanities-menu-link-child'
                    }
                ]
            },
            {
                name: 'custom products',
                routeId: 'ROUTE_CUT_TO_SIZE',
                queryString: '',
                translationId: 'custom_products',
                params: { outlet: '' },
                customClassTag: 'cts',
                show: this.props.canViewCutToSize && this.props.isLogged,
                dataQas: 'global_header-custom-products-menu-link',
                gtmLabel: 'ec_menu_header-elaborados',
                links: [
                    {
                        name: 'Cut to Size',
                        queryString: '?type=solo_corte',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        translationId: 'solo_corte',
                        params: { outlet: '' },
                        dataQas: 'global_header-cts-menu-link-child'
                    },
                    {
                        name: 'Custom Flooring',
                        queryString: '?type=soleria',
                        translationId: 'soleria',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        params: { outlet: '' },
                        dataQas: 'global_header-custom-floring-menu-link-child'
                    },
                    {
                        name: 'Baseboards',
                        queryString: '?type=rodapies',
                        translationId: 'rodapies',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        params: { outlet: '' },
                        dataQas: 'global_header-baseboards-menu-link-child'
                    },
                    {
                        name: 'Furniture',
                        queryString: '?type=custom_mobiliario',
                        translationId: 'furniture',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        params: { outlet: '' },
                        notShow: !this.props.canViewFurniture,
                        dataQas: 'global_header-furniture-menu-link-child'
                    },
                    {
                        name: 'Washbasing and shower trays',
                        queryString: '?type=configurable_bath',
                        translationId: 'washbasing_and_shower_trays',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        params: { outlet: '' },
                        notShow: !this.props.canViewConfigurableBath,
                        dataQas: 'global_header-special-custom-projects-menu-link-child'
                    },
                    {
                        name: 'Special custom projects',
                        queryString: this.props.canViewPreorder ? '?type=preorder' : '?type=budget',
                        translationId: 'special_custom_projects',
                        routeId: 'ROUTE_CUT_TO_SIZE',
                        params: { outlet: '' },
                        dataQas: 'global_header-special-custom-projects-menu-link-child'
                    },
                ]
            },
            {
                name: 'ppcc',
                routeId: 'ROUTE_CATALOG',
                queryString: '?type[0]=PPCC',
                translationId: 'professional_products',
                params: { outlet: '' },
                customClassTag: 'ppcc',
                show: this.props.centerPreferences && this.props.centerPreferences.productsByType.complementary && this.props.canViewCatalog,
                dataQas: 'global_header-professional-products-menu-link',
                gtmLabel: 'ec_menu_header-ppcc',
                links: [
                    {
                        name: 'Solumastik',
                        queryString: '?type[0]=PPCC&subtype[0]=Solumastik',
                        translationId: 'SOLUMASTIK',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-solumastik-menu-link-child'
                    },
                    {
                        name: 'Colorsil',
                        queryString: '?type[0]=PPCC&subtype[0]=Colorsil',
                        translationId: 'colorsil',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-colorsil-menu-link-child'
                    },
                    {
                        name: 'Mastidek',
                        queryString: '?type[0]=PPCC&subtype[0]=Mastidek',
                        translationId: 'MASTIDEK',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-mastidek-menu-link-child'
                    },
                    {
                        name: 'Colordek',
                        translationId: 'COLORDEK',
                        queryString: '?type[0]=PPCC&subtype[0]=Colordek',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-colordek-menu-link-child'
                    },
                    {
                        name: 'Abrasive',
                        queryString: '?type[0]=PPCC&subtype[0]=ABRASIVE',
                        translationId: 'ABRASIVE',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-abrasive-menu-link-child'
                    },
                    {
                        name: 'Others',
                        queryString: '?type[0]=PPCC&subtype[0]=OTHERS_PPCC',
                        translationId: 'OTHERS',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-others-menu-link-child'
                    }
                ]
            },
            {
                name: 'outlet',
                routeId: 'ROUTE_CATALOG',
                queryString: '',
                translationId: 'outlet',
                params: { outlet: 'outlet' },
                customClassTag: 'outlet',
                show: this.props.canViewCatalog && !this.props.canViewMktools,
                dataQas: 'global_header-outlet-menu-link',
                gtmLabel: 'ec_menu_header-outlet',
                links: outletBrandLinks
            },
            {
                name: 'Mall',
                routeId: 'ROUTE_LANDING_TEMPLATE',
                queryString: '',
                params: { slug: getMallLink(), outlet: '', preview: '', iso: '' },
                translationId: '',
                customClassTag: 'mall',
                show: showMall(),
                dataQas: 'global_header-mall-menu-link',
                links: this.props.userLandings
                    ? this.props.userLandings.map(landing => {
                          let today = new Date()
                          if (new Date(landing.end_at) > today) {
                              return {
                                  name: landing['slug'],
                                  routeId: 'ROUTE_LANDING_TEMPLATE',
                                  params: { slug: landing ? landing['slug'] : 'test', outlet: '', preview: '', iso: '' },
                                  translationId: ''
                              }
                          }
                      })
                    : []
            },
            {
                name: 'marketing',
                routeId: 'ROUTE_CATALOG',
                //queryString: this.props.mktoolShippingCenter ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}` : '?webHeading[0]=MK',
                queryString: '?webHeading[0]=MK',
                params: { outlet: '' },
                translationId: 'mktool',
                customClassTag: 'mktool',
                show: this.props.canViewMktools && !this.props.isWarehouse,
                dataQas: 'global_header-maketing-menu-link',
                links: [
                    {
                        name: 'Muestras',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=01`
                            : '?webHeading[0]=MK&type[0]=01',
                        routeId: 'ROUTE_CATALOG',
                        translationId: 'SAMPLES',
                        params: { outlet: '' },
                        dataQas: 'global_header-muestras-menu-link-child'
                    },
                    {
                        name: 'Expositores y torres',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=08`
                            : '?webHeading[0]=MK&type[0]=08',
                        translationId: 'DISPLAYS',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-expositores-torres-menu-link-child'
                    },
                    {
                        name: 'Cajas',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=07`
                            : '?webHeading[0]=MK&type[0]=07',
                        translationId: 'BOXES',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-cajas-menu-link-child'
                    },
                    {
                        name: 'Test Kits',
                        translationId: 'TEST_KITS',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=12`
                            : '?webHeading[0]=MK&type[0]=12',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-test-kits-menu-link-child'
                    },
                    {
                        name: 'Papeleria',
                        translationId: 'LITERATURE',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=04`
                            : '?webHeading[0]=MK&type[0]=04',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-papeleria-menu-link-child'
                    },
                    {
                        name: 'Merchandising',
                        translationId: 'MERCHANDISING',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=03`
                            : '?webHeading[0]=MK&type[0]=03',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-merchandising-menu-link-child'
                    }
                ]
            },
            {
                name: 'lowesBrochures',
                routeId: 'ROUTE_CATALOG_LOWES',
                queryString: '',
                translationId: 'brochures',
                params: { filter: 'brochures' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType !== 'ADMIN',
                dataQas: 'global_header-lowes-brochures-menu-link'
            },
            {
                name: 'lowesCustomerSamples',
                routeId: 'ROUTE_CATALOG_LOWES',
                queryString: '',
                translationId: 'customer_samples',
                params: { filter: 'customer-samples' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType !== 'ADMIN',
                dataQas: 'global_header-lowes-samples-menu-link'
            },
            {
                name: 'lowesAdmin',
                routeId: 'ROUTE_CATALOG_LOWES_ADMIN',
                queryString: '',
                translationId: 'lowes',
                params: { filter: 'home' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType === 'ADMIN',
                dataQas: 'global_header-lowes-admin-menu-link'
            },
            {
                name: 'showroom',
                routeId: 'ROUTE_CATALOG',
                queryString: this.props.mktoolShippingCenter ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}` : '?webHeading[0]=SH',
                params: { outlet: '' },
                translationId: 'showroom',
                customClassTag: 'showroom',
                dataQas: 'global_header-showroom-menu-link',
                show: this.props.canViewMktools && !this.props.isWarehouse,
                links: [
                    {
                        name: 'Identificadores',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=05`
                            : '?webHeading[0]=SH&type[0]=05',
                        routeId: 'ROUTE_CATALOG',
                        translationId: 'TAGS',
                        params: { outlet: '' },
                        dataQas: 'global_header-identificadores-menu-link'
                    },
                    {
                        name: 'Anclajes',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=06`
                            : '?webHeading[0]=SH&type[0]=06',
                        translationId: 'ANCHORING',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-anclajes-menu-link'
                    },
                    {
                        name: 'Expositores y torres',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=07`
                            : '?webHeading[0]=SH&type[0]=08',
                        translationId: 'DISPLAYS',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' }
                    },
                    {
                        name: 'Mobiliario',
                        translationId: 'furniture',
                        queryString: this.props.mktoolShippingCenter
                            ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}&type[0]=10`
                            : '?webHeading[0]=SH&type[0]=10',
                        routeId: 'ROUTE_CATALOG',
                        params: { outlet: '' },
                        dataQas: 'global_header-mobiliario-menu-link'
                    }
                ]
            },
            {
                name: 'warehouse',
                routeId: 'ROUTE_CATALOG',
                queryString: this.props.mktoolShippingCenter ? `?webHeading[0]=WH&mktoolShippingCenter[0]=${this.props.mktoolShippingCenter}` : '?webHeading[0]=WH',
                params: { outlet: '' },
                translationId: 'warehouse',
                customClassTag: 'warehouse',
                show: this.props.canViewMktools && this.props.isWarehouse,
                dataQas: 'global_header-warehouse-menu-link'
            }
        ]
        let links = [
            {
                name: 'slabs',
                routeId: 'ROUTE_CATALOG',
                queryString: '?type[0]=TABLA',
                params: { outlet: '' },
                translationId: 'tables',
                customClassTag: 'slabs',
                show: this.props.centerPreferences && this.props.centerPreferences.productsByType.slab && this.props.canViewCatalog,
                gtmLabel: 'ec_home_banner_seccion-slabs'
            },

            {
                name: 'standard products',
                routeId: 'ROUTE_CATALOG',
                queryString:
                    '?type[0]=TABLA&type[1]=CTS&subtype[0]=SOLERIA&subtype[1]=Optimma&type[2]=BAÑOS&subtype[2]=wall_clading&subtype[3]=Washbasin&type[3]=BAÑOS&subtype[4]=Shower%20tray&?type[4]=FREGADERO',
                params: { outlet: '' },
                translationId: 'standard_products',
                customClassTag: 'standard',
                show: this.props.canViewCatalog && !this.props.canViewMktools,
                gtmLabel: 'ec_home_banner_seccion-standard'
            },
            {
                name: 'custom products',
                routeId: 'ROUTE_CUT_TO_SIZE',
                queryString: '',
                translationId: 'custom_products',
                params: { outlet: '' },
                customClassTag: 'cts',
                show: this.props.canViewCutToSize && this.props.isLogged,
                gtmLabel: 'ec_home_banner_seccion-cts'
            },
            {
                name: 'ppcc',
                routeId: 'ROUTE_CATALOG',
                queryString: '?type[0]=PPCC',
                translationId: 'professional_products',
                params: { outlet: '' },
                customClassTag: 'ppcc',
                show: this.props.centerPreferences && this.props.centerPreferences.productsByType.complementary && this.props.canViewCatalog,
                gtmLabel: 'ec_home_banner_seccion-ppcc'
            },

            {
                name: 'outlet',
                routeId: 'ROUTE_CATALOG',
                queryString: '',
                translationId: 'outlet',
                params: { outlet: 'outlet' },
                customClassTag: 'outlet',
                show: this.props.canViewCatalog && !this.props.canViewMktools,
                gtmLabel: 'ec_home_banner_seccion-outlet'
            },
            {
                name: 'promo',
                routeId: 'ROUTE_CATALOG',
                queryString: '?type[0]=TABLA&promo=true',
                translationId: 'promotions',
                params: { outlet: '' },
                customClassTag: '',
                show: this.props.hasPromoActivated
            },
            {
                name: 'lowesBrochures',
                routeId: 'ROUTE_CATALOG_LOWES',
                queryString: '',
                translationId: 'brochures',
                params: { filter: 'brochures' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType !== 'ADMIN'
            },
            {
                name: 'lowesCustomerSamples',
                routeId: 'ROUTE_CATALOG_LOWES',
                queryString: '',
                translationId: 'customer_samples',
                params: { filter: 'customer-samples' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType !== 'ADMIN'
            },
            {
                name: 'lowesAdmin',
                routeId: 'ROUTE_CATALOG_LOWES_ADMIN',
                queryString: '',
                translationId: 'lowes',
                params: { filter: 'home' },
                customClassTag: '',
                show: this.props.selectedCenter === this.props.lowesCenerId && this.props.userType === 'ADMIN'
            },
            {
                name: 'profile',
                routeId: 'ROUTE_PROFILE',
                queryString: '',
                translationId: 'profile',
                customClassTag: 'profile',
                params: { filter: 'configuration', open: '' },
                show: this.props.canViewCatalog
            },
            {
                name: 'mkTool',
                routeId: 'ROUTE_CATALOG',
                queryString: mktoolShippingCenter ? `?webHeading[0]=MK&mktoolShippingCenter[0]=${mktoolShippingCenter}` : '?webHeading[0]=MK',
                translationId: 'mktool',
                params: { outlet: '' },
                customClassTag: '',
                show:
                    this.props.canViewCatalog &&
                    this.props.canViewMktools &&
                    this.props.centerMktool &&
                    (this.props.selectedCenter === this.props.showroomCenterId || this.props.selectedCenter === this.props.DRIUSACenterId)
            },
            {
                name: 'showRoom',
                routeId: 'ROUTE_CATALOG',
                queryString: mktoolShippingCenter ? `?webHeading[0]=SH&mktoolShippingCenter[0]=${mktoolShippingCenter}` : '?webHeading[0]=SH',
                translationId: 'showroom',
                params: { outlet: '' },
                customClassTag: '',
                show:
                    this.props.canViewCatalog &&
                    this.props.canViewMktools &&
                    this.props.centerShowroom &&
                    (this.props.selectedCenter === this.props.showroomCenterId || this.props.selectedCenter === this.props.DRIUSACenterId)
            },
            {
                name: 'warehouse',
                routeId: 'ROUTE_CATALOG',
                queryString: '?webHeading[0]=WH',
                params: { outlet: '' },
                translationId: 'warehouse',
                customClassTag: '',
                show: this.props.canViewCatalog &&
                    this.props.canViewMktools &&
                    this.props.centerWarehouse &&
                    this.props.selectedCenter === this.props.warehoseCenterId
            }
        ]

        const LinkList = ({ children, responsive, customClass = "", index }) =>
            <li className={responsive ? 'nav-item ' + customClass : customClass} onClick={() => {
                if (customClass) {
                    let elementsClass = customClass.split(' ')
                    //console.log({ "selector": elementsClass });
                    let selector = (elementsClass[0].length > 0 ? `.${elementsClass[0]}` : "") + (elementsClass[1].length > 0 ? `.${elementsClass[1]}` : "")
                    let element = selector !== "" ? document.querySelectorAll(selector) : null
                    if (element && element[0]) {
                        if (element[0].children && element[0].children[0]) {
                            element[0].children[0].click()
                        }
                    }
                }
                if (responsive) {
                    [...document.querySelectorAll(`#responsive-extra-menu-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                    [...document.querySelectorAll(`#arrow-down-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                    [...document.querySelectorAll(`#arrow-up-${index}`)].map(e => {
                        e.hidden = !e.hidden
                    });
                }
            }}>{children}</li>

        const settings = {
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            dots: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: false
        }


        let hasBackground = false

        return (
                <React.Fragment>
                    
                    {
                        !this.props.comp && !this.props.footer &&
                      <div className={'product-links-container'}>
                          {
                              links.map((value, index) => (
                                value.show &&
                                <LinkList className='link-hover' responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag}`} key={index}>
                                    <LocalizedLink routeId={value.routeId} queryString={value.queryString} params={value.params} className={responsive ? 'nav-link' : null} gtmLabel={value.gtmLabel}>
                                        <Translation id={value.translationId} />
                                    </LocalizedLink>
                                </LinkList>
                              ))
                          }
                      </div>

                    }
                    {
                      !this.props.comp && !this.props.footer &&
                      <div className={'container product-links-slider-container'}>
                          <Slider {...settings}>
                              {
                                  links.filter((value) => {
                                      //TODO Refactor this please
                                      return ['slabs', 'ppcc', 'elaborados', 'sinks', 'outlet', 'standard', 'cts'].includes(value.customClassTag);
                                  }).map((value, index) => (
                                    value.show &&
                                      <div key={index}>
                                          <div style={{margin: '0 10px'}}>
                                              <LinkList className='link-hover' responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag}`} key={index}>
                                                  <LocalizedLink routeId={value.routeId} queryString={value.queryString} params={value.params} className={responsive ? 'nav-link' : null}>
                                                      <Translation id={value.translationId} />
                                                  </LocalizedLink>
                                              </LinkList>
                                          </div>

                                      </div>

                                  ))
                              }
                          </Slider>
                      </div>

                    }
                    {
                        this.props.footer && 
                        linksMenu.map((value, index) => (
                            value.show &&
                            <LinkList className='link-hover' responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag}`} key={index}>
                                <LocalizedLink routeId={value.routeId} queryString={value.queryString} params={value.params} className={responsive ? '' : null}>
                                    {
                                        value.translationId &&
                                        <Translation id={value.translationId} />
                                    }
                                    {
                                        !value.translationId &&
                                        value.name
                                    }      
                                </LocalizedLink>
                            </LinkList> 
                        ))
                    }
                    {
                        this.props.comp &&
                        <ul className={`nav-burger ${responsive ? "responsive" : ""}`} onMouseOver={() => {
                            if (!responsive) {
                                let backgroundHeight = 0;
                                //document.querySelectorAll(".header-links-separator").forEach(e => e.hidden = false);
                                document.querySelectorAll(".extra-menu").forEach(e => {
                                    e.hidden = false
                                    e.classList.add("fade-in")
                                    backgroundHeight = Math.max(backgroundHeight, e.offsetHeight)
                                })
                                document.querySelectorAll(".background").forEach(e => {
                                    e.hidden = false
                                    e.style.height = backgroundHeight + 'px'
                                });
                            }

                        }} onMouseOut={() => {
                            if (!responsive) {
                                //document.querySelectorAll(".header-links-separator").forEach(e => e.hidden = true);
                                document.querySelectorAll(".extra-menu").forEach(e => {
                                    e.hidden = true
                                    e.classList.remove("fade-in")
                                })
                                document.querySelectorAll(".background").forEach(e => e.hidden = true);
                            }

                        }}>
                            {
                                linksMenu.map((value, index) => {return value.show &&
                                    <li className='link-hover' index={index} responsive={responsive} params={values.params ? values.params : {}} customClass={(customClass ? customClass : "") + ` ${value.customClassTag} header-menu-link ${responsive ? "responsive" : ""}`} key={index}>
                                        <LocalizedLink routeId={value.routeId}
                                                       header={true}
                                                       params={value.params}
                                                       queryString={value.queryString}
                                                       className='a-hover-menu v2'
                                                       dataQas={value.dataQas}
                                                       gtmLabel={value.gtmLabel}>

                                            {
                                                value.translationId &&
                                                <Translation id={value.translationId} />
                                            }
                                            {
                                                !value.translationId &&
                                                value.name
                                            }

                                        </LocalizedLink>
                                        {
                                            responsive &&
                                            <React.Fragment>
                                            <span className='arrow-down' id={`arrow-down-${index}`} onClick={()=>{
                                                [...document.querySelectorAll(`#responsive-extra-menu-${index}`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                                [...document.querySelectorAll(`#arrow-down-${index}`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                                [...document.querySelectorAll(`#arrow-up-${index}`)].map(e => {
                                                    e.hidden = !e.hidden
                                                });
                                            }}><i className={`fal fa-chevron-down`}></i></span>
                                                <span className='arrow-down' id={`arrow-up-${index}`} hidden={true} onClick={() => {
                                                    [...document.querySelectorAll(`#responsive-extra-menu-${index}`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                    [...document.querySelectorAll(`#arrow-down-${index}`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                    [...document.querySelectorAll(`#arrow-up-${index}`)].map(e => {
                                                        e.hidden = !e.hidden
                                                    });
                                                }}><i className={`fal fa-chevron-up`}></i></span>
                                            </React.Fragment>
                                        }
                                        {
                                            !hasBackground && <div className='background shadow-sm' hidden={true} gtm-label="ec_submenu_header"/>
                                        }
                                        {
                                            hasBackground = true
                                        }
                                        <div className="extra-menu" id={window.innerWidth <= 1199 ? `responsive-extra-menu-${index}` : `extra-menu-${index}`} hidden={true}>
                                            <ul className={`group-links header-menu-link`}>
                                                {
                                                    value.links &&
                                                    value.links.map((link, i) => (
                                                        link && !link.notShow &&
                                                        <LinkList className='link-hover' responsive={responsive} params={values.params ? values.params : {}} key={i}>
                                                            <LocalizedLink routeId={link.routeId}
                                                                           header={true}
                                                                           params={link.params}
                                                                           queryString={link.queryString}
                                                                           className={`${responsive ? 'nav-link' : null} sub-menu-link v2`}
                                                                           control={value.name === "custom products" ? true : false}
                                                                           dataQas={link.dataQas}
                                                                           gtmLabel="ec_submenu_header"
                                                            >
                                                                {
                                                                    link.translationId !== "" &&
                                                                    <Translation id={link.translationId} />
                                                                }
                                                                {
                                                                    link.translationId === "" &&
                                                                    <React.Fragment>{link.name}</React.Fragment>
                                                                }


                                                            </LocalizedLink>
                                                        </LinkList>

                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    }
                </React.Fragment>
        )
    }

}

export default MenuLinksV2;

function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
      <i
        className={'fal fa-chevron-right'}
        style={{ ...style, display: 'block', position: 'absolute', right: 0, top: '40%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
        onClick={onClick}
      />
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props
    return (
      <i
        className={'fal fa-chevron-left'}
        style={{ ...style, display: 'block', position: 'absolute', top: '40%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
        onClick={onClick}
      />
    )
}
